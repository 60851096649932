<template>
    <div>
        <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
        <CModalExtended
            :title="titleModalImdgCode"
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :show.sync="modalCodeActive"
            @update:show="$emit('close-modal-imdg-code');"
        >
            <CRow>
                <CCol sm="12" lg="12" xl="12">
                <CRow>
                    <CCol sm="12" lg="4" xl="4"  class="d-flex align-items-center">
                        <picture-input
                            v-if="modalCodeActive"
                            ref="imdgImage"
                            width="186"
                            height="186"
                            accept="image/jpeg,image/png,image"
                            size="10"
                            :custom-strings="imgInputTexts"
                            @change="handleFileUploadImage"
                            :imgSrc="formImdg.imageUrl"
                        >
                        </picture-input>   
                    </CCol>
                    <CCol sm="12" lg="8" xl="8">
                        <CRow>
                            <CCol sm="12" lg="12" xl="12">            
                                <CSelect
                                    :label="$t('label.imdgClass')"
                                    addLabelClasses="text-right required"
                                    :placeholder="$t('label.select')"
                                    :options="computedSelectFormatted"                   
                                    :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                    :value.sync="formImdg.ImdgClassId"
                                    v-uppercase
                                    :is-valid="hasError($v.formImdg.ImdgClassId)"
                                    :invalid-feedback="errorMessage($v.formImdg.ImdgClassId)"
                                    @change="validateImdgCode"
                                >
                                     
                                </CSelect>
                            </CCol>                    
                            <CCol sm="12" lg="12" xl="12">            
                                <CTextarea
                                rows="2"
                                :label="$t('label.imdgDivision')+' '+('(ES)')"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :placeholder="$t('label.imdgDivisionName')+' '+('(ES)')"
                                v-uppercase
                                :disabled="disabled == 1"
                                :is-valid="hasError($v.formImdg.ImdgNameEs)"
                                :invalid-feedback="errorMessage($v.formImdg.ImdgNameEs)"
                                v-model="$v.formImdg.ImdgNameEs.$model"
                            />
                            </CCol>    
                            <CCol sm="12" lg="12" xl="12">            
                                <CTextarea
                                rows="2"
                                :label="$t('label.imdgDivision')+' '+('(EN)')"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :placeholder="$t('label.imdgDivisionName')+' '+('(EN)')"
                                v-uppercase
                                :disabled="disabled == 1"
                                :is-valid="hasError($v.formImdg.ImdgNameEn)"
                                :invalid-feedback="errorMessage($v.formImdg.ImdgNameEn)"
                                v-model="$v.formImdg.ImdgNameEn.$model"
                            />
                            </CCol>            
                            <CCol sm="12" lg="12" xl="12">            
                                <CInput
                                :label="$t('label.classCode')"
                                placeholder="0"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                v-model.trim="$v.formImdg.ImdgCod.$model"
                                :is-valid="hasError($v.formImdg.ImdgCod)"
                                :disabled="disabled == 1"
                                :invalid-feedback="errorMessage($v.formImdg.ImdgCod)"
                                />
                            </CCol>          
                            <CCol sm="12" lg="12" xl="12" v-if="isEditImdg">            
                                <CSelect
                                :value.sync="formImdg.Status"
                                :is-valid="statusSelectColor"
                                :horizontal="{label:'col-sm-12 col-lg-4 col-xl-4', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :label="$t('label.status')"
                                :options="selectOptions"
                                addLabelClasses="required text-right"
                                />
                            </CCol>
                        </CRow> 
                    </CCol>
                    <CCol sm="12" lg="4" xl="4"  class="d-flex align-items-center">                     
                        <div class="text-invalid-feedback text-center" v-if="$v.formImdg.ImdgRoute.$error">
                            {{$t('label.badImage')}}
                        </div> 
                    </CCol>
                </CRow>
                </CCol>

            </CRow>
            <template #footer >
                <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    :disabled="apiStateLoading"
                    @click.stop="changeStatus"
                >
                    <CIcon name="checkAlt" />{{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
    </div>
</template>

<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import PictureInput from '@/components/picture-input';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import ImdgValidation from '@/_validations/imdg/imdgValidation';
    import { GenerateImgPlaceholder, DateFormater } from '@/_helpers/funciones';
    
    //caso excepcional
   // const VUE_APP_PATH = `${this.$store.getters["connection/getBase"]}`;

    //data
    function data() {
        return {
            apiStateLoading: false,
            Loading: false,
            modalCodeActive: false,
            ImdgRoute: '', 
            image: '',
            //imdg     
            formImdg:{
                ImdgId: "",
                ImdgClassId: "", 
                ImdgNameEs: "",  
                ImdgNameEn: "", 
                ImdgCod: "", 
                Status: 0,
                FgActImdg: true,
                ImdgRoute: "",
                imdgCodeId: "",
                imageUrl:'/img/uploadPhotoEs.svg',
            },
            isEditImdg: false,
            isEditImdgClass: false,
            loadingImdgTable:false,
            imdgItems:[],
            selectImdgClass:[],
            disabled: 0,
            imdgClassItems:[],
            //
        }
    }
    async function getImdgClassList() {
        await this.$http
        .get('ImdgClass-list')
        .then((response) => (this.imdgClassItems = response.data.data))
    }

    async function getImdgClassById(ImdgClassId) {
        return await  this.$http.get(`ImdgClass-by-id?ImdgClassId=${ImdgClassId}`);
    }

    async function getSelectImdgClasses() {
         await this.$http
        .get('ImdgClass-list?Filter=ACTIVO')
        .then((response) => {
            this.selectImdgClass = response.data.data;
        });
    }
    
    async function getImdgListByClass(imdgClassId = "") {
        this.loadingImdgTable = true;
        await this.$http.get(`Imdg-list?ImdgClassId=${imdgClassId}`).then((response) => {
            this.imdgItems = response.data.data;
            this.loadingImdgTable = false;
        });
    }

    function changeSelectImdgValue(event) {
        this.formImdg.ImdgClassId = event.target.value;
        this.getImdgListByClass(this.formImdg.ImdgClassId);
        //this.verifyImdgClass(this.formImdg.ImdgClassId);
    }
    //methods
    function closeModal() {
        this.modalCodeActive = false
        this.$emit("close-modal-imdg-code", this.modalCodeActive);
        this.resetImdgForm();
    }

    function resetImdgForm () {
        this.ImdgRoute = '';
        this.formImdg = {
            ImdgClassId: "", 
            ImdgNameEs: "", 
            ImdgNameEn: "", 
            ImdgCod: "", 
            Status: 0,
            ImdgRoute: "",   
            imdgCodeId: "",
            FgActImdg: true,
        };
       
        this.isEditImdg = false; 
        
        this.$v.formImdg.$reset();
        if (this.$refs.imdgImage) {
            this.$refs.imdgImage.removeImage();
            this.$refs.imdgImage.value = null;
            this.$refs.imdgImage.file = null;
        }
    }

    //Methods

    function changeStatus() {
        if(this.formImdg.FgActImdg !== '' && this.formImdg.ImdgId !== 0){
            if(this.formImdg.FgActImdg !== this.formImdg.Status){              
                    this.$swal
                        .fire(this.alertProperties({
                            text: `${this.$t('label.changeStatusQuestion')+'?'}`,
                        }))     
                    .then((result) => {
                    if (result.isConfirmed) {
                        this.submitImgFile();
                    }
                })
            }else{
                this.submitImgFile();
            }
        }else{
            this.submitImgFile();
        }
    }

    async function submitImgFile() {
        try {
            this.isSubmit = true;
            this.Loading = true;
            this.$v.formImdg.$touch();
            if (this.$v.formImdg.$error) {
                this.isSubmit = false;
                this.Loading = false;
                throw this.$t('label.errorsPleaseCheck');
            }

            if((this.ImdgRoute == this.formImdg.ImdgRoute) && this.isEditImdg)
                this.submitImg();
            if( ((this.ImdgRoute != this.formImdg.ImdgRoute) && this.isEditImdg) || !this.isEditImdg) {  
                await this.$http.file('Imdg-list',  this.formImdg.ImdgRoute,  this.ImdgRoute)
                .then((response) => {
                    this.formImdg.ImdgRoute = response.data.data.files[0].path;
                        this.submitImg();
                    }).catch((err) => {
                        this.notifyError({text: err});
                    }); 
            } 

            this.isSubmit = false;
            this.Loading = false;
            
        } catch (e) {
            this.notifyError({text: e});
            this.Loading = false;
        }
        
    }

    async function submitImg() {
        try {
            const ImdgJson ={
                ImdgClassId: this.formImdg.ImdgClassId,
                ImdgNameEs: this.formImdg.ImdgNameEs,
                ImdgNameEn: this.formImdg.ImdgNameEn,
                ImdgCod: this.formImdg.ImdgCod.toUpperCase(),
                ImdgRoute: this.formImdg.ImdgRoute,  
            };
            if(this.isEditImdg){
                ImdgJson.ImdgId = this.formImdg.ImdgId;
                ImdgJson.Status = this.formImdg.Status;
            }
            let method = this.isEditImdg?'PUT':'POST';
            let ruta = this.isEditImdg?'Imdg-update':'Imdg-insert';
            this.$http.ejecutar(method, ruta, ImdgJson, { root: 'ImdgJson' })
                .then((response) => {
                    if ((response.status = 200 || 201)) {
                        const messageSuccess = response.data.data[0].Response;
                        this.notifySuccess({text: messageSuccess})
                        this.resetImdgForm();
                        this.$emit('updated-imdg-code-list');
                        this.getImdgListByClass();
                        this.closeModal();
                    }
                }).catch((e) => {
                    this.notifyError({text: e});
                });
        } catch (e) {
            this.notifyError({text: e});
        }

    } 

    function handleFileUploadImage() {
        this.formImdg.ImdgRoute = this.$refs.imdgImage.file;
        this.$v.formImdg.ImdgRoute.$touch();  
    }

    function  validateImdgCode(){
        const resultado = this.selectImdgClass.find( item => item.ImdgClassId === this.formImdg.ImdgClassId );
        this.formImdg.imdgCodeId=resultado.ImdgClassAbbrev;
    }

    function statusSelectColor(){
        return this.formImdg.Status == 1;
    }
    
    //computed
    function computedSelectFormatted() {
        let list = [];
        if(this.selectImdgClass.length > 0) {
            list = this.selectImdgClass.sort( 
                (a, b) => a.ImdgClassAbbrev - b.ImdgClassAbbrev
            );
        }
        return list.map((item) => {
            let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
            return {
                label: item[`ImdgClassName${_lang}`] ? item.ImdgClassAbbrev + ' - ' +  item[`ImdgClassName${_lang}`] : 'N/A',
                value: item.ImdgClassId,
            };
        });
    }   

    function selectOptions() {
        return[
        {
            value: true,
            label: this.$t('label.ACTIVO'),
        },
        {
            value: false,
            label: this.$t('label.INACTIVO'),
        },
        ]
    }
    

    //watch
    export default {
        name: 'modal-imdg-code',
        data,
        mixins: [GeneralMixin, ModalMixin],
        props: {
            modal: {
                type: Boolean,
                default: false,
            },
            modalEdit:{
                type: Boolean,
                default: false,
            },
            imdgCodeData: {
                type: Object,
                default: () => { }
            },
            titleModalImdgCode:{ 
                type: String,
                default: false,
            },
        },
        components: {
            PictureInput,
        },
        directives: UpperCase,
        validations:ImdgValidation,
        methods:{
            closeModal,
            handleFileUploadImage,
            resetImdgForm,
            getSelectImdgClasses,
            getImdgListByClass,
            getImdgClassList,
            getImdgClassById,
            changeSelectImdgValue,
            submitImgFile,
            submitImg,
            validateImdgCode,
            changeStatus,
            statusSelectColor
        },
        computed: {
            computedSelectFormatted,
            selectOptions
            
         },
        watch:{
            modal: async function(val){
                this.modalCodeActive = val;
                if(val){
                    if (this.modalEdit){
                        if(this.imdgCodeData.ImdgRoute != null && this.imdgCodeData.ImdgRoute != ''){
                            this.formImdg.imageUrl = `${this.$store.getters["connection/getBase"]}${this.imdgCodeData.ImdgRoute.replace('public/', '', null, 'i')}`;
                            this.ImdgRoute = this.imdgCodeData.ImdgRoute;
                            this.formImdg.ImdgRoute = this.ImdgRoute ? this.ImdgRoute : '';

                        }else{
                            let _lang = this.$i18n.locale;
                            this.formImdg.imageUrl = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
                        }
                        this.formImdg.ImdgClassId = this.imdgCodeData.ImdgClassId, 
                        this.formImdg.ImdgNameEs = this.imdgCodeData.ImdgNameEs, 
                        this.formImdg.ImdgNameEn = this.imdgCodeData.ImdgNameEn,   
                        this.formImdg.ImdgCod = this.imdgCodeData.ImdgCod, 
                        this.formImdg.Status = this.imdgCodeData.FgActImdg,
                        this.formImdg.ImdgId = this.imdgCodeData.ImdgId,  
                        this.formImdg.FgActImdg = this.imdgCodeData.FgActImdg,
                        this.formImdg.imdgCodeId = this.imdgCodeData.ImdgClassAbbrev,

                        this.isEditImdg = true;
                        this.$v.$touch();
                    }else if (!this.modalEdit){
                        this.resetImdgForm();
                        let _lang = this.$i18n.locale;
                        this.formImdg.imageUrl = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
                    }
                    this.getSelectImdgClasses(); 
                }
            },           
            /*idState*/
        }
    }
</script>

<style lang="scss">

</style>

